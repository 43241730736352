<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Change Password</h4>
          </CCardHeader>
          <CCardBody>
          <b-form>

  		      <b-form-group id="input-group-1" label="Current Password:" label-for="input-1">
  		      <b-form-input v-model="oldPasswd" type="password" placeholder="Enter Current Password" required></b-form-input>
  		      </b-form-group>

  		      <b-form-group id="input-group-2" label="New Password:" label-for="input-2">
  		        <b-form-input v-model="newPasswd" type="password" placeholder="Enter New Password"  required></b-form-input>
  		      </b-form-group>

  		      <!--b-form-group id="input-group-3" label="Confirm Password:" label-for="input-3">
  		        <b-form-input  v-model="confirmPasswd" type="password" placeholder="Confirm New Password" required></b-form-input>
  		      </b-form-group-->
  		      <p v-if="msg">{{ msg }}</p>
						<transition name="hint" appear>
							<div v-if='passwordValidation.errors.length > 0 && !submitted' class='hints'>
								<h2>Hints</h2>
								<p v-for='error in passwordValidation.errors' style="margin-bottom:0px">{{error}}</p>
								<br />
							</div>
						</transition>
						<div class="already-used" v-if='notSamePasswords'>
							<p>Existing Passwords can't be used as New Password</p>
						</div>
  		      <b-button type="button" @click="changePasswd" :disabled="submitable" variant="primary">Change Password</b-button>

		      </b-form>

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';

  export default {
    data() {
      return {
          oldPasswd: '',
          newPasswd: '',
          //confirmPasswd: '',
          msg: '',
					rules: [
            { message:'One lowercase letter required.', regex:/[a-z]+/ },
            { message:"One uppercase letter required.",  regex:/[A-Z]+/ },
            { message:"15 characters minimum.", regex:/.{15,}/ },
            { message:"Symbol required.", regex:/[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/ },
            { message:"One number required.", regex:/[0-9]+/ }
          ],
					submitable: false,
					submitted: false,
          seen: false
        } ;

    },
    methods: {
			focusNext(e) {
					const inputs = Array.from(e.target.form.querySelectorAll('input[class="input"]'));
					const index = inputs.indexOf(e.target);

					if(index === 1){
						this.passwordExp();
					} else if (index < inputs.length) {
						inputs[index + 1].focus();
					}
			},
			async changePasswd() {

        if (!this.oldPasswd || !this.newPasswd ) {
            this.msg = "Old Password and New Password Required";
        } else {

            try {
                  const parameters = { userName: this.$store.getters.getUser, existingPassword: this.oldPasswd, newPassword: this.newPasswd };
                  const resp = await AuthService.updateExpiryPassword(parameters);
                  if(resp.data.status=="OK"){
                        this.$store.dispatch('logout');
                        this.$router.push({ path: '/auth/login' });
                  } else {  this.msg = resp.data.message; }

              } catch (error) {
                console.log(error);
              }
            }
        }

    },
		computed: {
			notSamePasswords () {
				if (this.passwordsFilled) {
					return (this.oldPasswd === this.newPasswd)
				} else {
					return false
				}
			},
			passwordsFilled () {
				return (this.oldPasswd !== '' && this.newPasswd !== '')
			},
			passwordValidation () {
				let errors = []
				if(this.newPasswd !== ''){
					for (let condition of this.rules) {
						if (!condition.regex.test(this.newPasswd)) {
							errors.push(condition.message)
						}
					}
				}
				if (errors.length === 0) {
					this.submitable = (this.oldPasswd !== '' && this.newPasswd !== '' && this.oldPasswd !== this.newPasswd) ? false : true;
					return { valid:true, errors }
				} else {
					this.submitable = true;
					return { valid:false, errors }
				}
			}
		}
  }
</script>
